<template>
  <div>
    <b-row>
      <b-col
        v-if="loading"
        cols="12"
        class="d-flex align-items-center justify-content-center"
      >
        <!-- Loading Indicator -->
        <b-spinner label="Loading..." />
      </b-col>
    </b-row>
    <div v-if="!loading">
      <div style="display: flex; margin: 15px; ">
        <b-btn
          style="margin: auto;width: 20vw; height: 5vh; font-size: x-large;"
          @click="printInvoice"
        >
          Print
        </b-btn>
      </div>
      <b-card
        id="invoice-card"
        style="width: fit-content; margin: auto;"
      >

        <b-card-title style="font-size: xx-large; text-align: center;">
          Invoice Info
        </b-card-title>
        <b-card-text v-show="invoiceData.request_code">
          <strong>Request Code: </strong>{{ invoiceData.request_code }}
        </b-card-text>
        <b-card-text v-show="invoiceData.payment_method">
          <strong>Payment Method: </strong>{{ invoiceData.payment_method }}
        </b-card-text>
        <b-card-text v-show="invoiceData.payment_status">
          <strong>Payment Status: </strong>{{ invoiceData.payment_status }}
        </b-card-text>

        <div
          v-if="invoiceData.delegator"
          class="d-flex justify-content-between"
        >
          <diV class="mx-2 my-2">
            <b-card-title style="font-size: xx-large;">
              Delegator:
            </b-card-title>

            <b-card-text
              v-if="invoiceData.delegator"
              class="d-flex flex-column"
            >
              <span v-show="invoiceData.delegator.name"><strong> Name: </strong>{{ invoiceData.delegator.name }}</span>
              <span v-show="invoiceData.delegator.phone"><strong> Phone: </strong>{{ invoiceData.delegator.phone }}</span>
              <span v-show="invoiceData.delegator.email"><strong> Email: </strong>{{ invoiceData.delegator.email }}</span>
            </b-card-text>
          </diV>

          <diV
            v-if="invoiceData.office"
            class="mx-2 my-2"
          >
            <b-card-title style="font-size: xx-large;">
              office:
            </b-card-title>

            <b-card-text
              v-if="invoiceData.office"
              class="d-flex flex-column"
            >
              <span v-show="invoiceData.office.name"><strong> Name: </strong>{{ invoiceData.office.name }}</span>
              <span v-show="invoiceData.office.country"><strong> Country: </strong>{{ invoiceData.office.country }}</span>
              <span v-show="invoiceData.office.tax"><strong> Tax: </strong>{{ invoiceData.office.tax }} %</span>
              <span v-show="invoiceData.office.other_tax"><strong> Other Tax: </strong>{{ invoiceData.office.other_tax }} %</span>
            </b-card-text>
          </diV>
        </div>

        <div class="d-flex">

          <table
            id="customers"
          >
            <tr>
              <th>Main Certificate</th>
              <th>Sub Certificate</th>
              <th>Price</th>
            </tr>
            <tr>
              <td>{{ invoiceData.certificate.main }}</td>
              <td>{{ invoiceData.certificate.sub }}</td>
              <td>{{ invoiceData.price.certificate }}</td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="text-center"
              >
                Tax
              </td>
              <td>{{ invoiceData.price.tax }}</td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="text-center"
              >
                Other Tax
              </td>
              <td>{{ invoiceData.price.other_tax }}</td>
            </tr>
            <tr>
              <td
                colspan="2"
                class="text-center"
              >
                Total
              </td>
              <td>{{ invoiceData.price.total }}</td>
            </tr>
          </table>

        </div>

        <diV
          v-if="invoiceData.factory"
          class="mx-2 my-2"
        >
          <b-card-title style="font-size: xx-large;">
            factory:
          </b-card-title>

          <b-card-text
            class="d-flex flex-column"
          >
            <span v-show="invoiceData.factory.name"><strong>Factory Name: </strong>{{ invoiceData.factory.name }}</span>
            <span v-show="invoiceData.factory.address"><strong>Factory Address: </strong>{{ invoiceData.factory.address }}</span>
            <span v-show="invoiceData.factory.phone"><strong>Factory Phone: </strong>{{ invoiceData.factory.phone }}</span>
          </b-card-text>
        </diV>

        <diV
          v-if="invoiceData.offer"
          class="mx-2 my-2"
        >
          <b-card-title style="font-size: xx-large;">
            offer:
          </b-card-title>

          <b-card-text
            class="d-flex flex-column"
          >
            <span v-show="invoiceData.offer"><strong>Name: </strong>{{ invoiceData.offer.name }}</span>
            <span v-show="invoiceData.offer"><strong>Amount: </strong>{{ invoiceData.offer.amount }} %</span>
          </b-card-text>
        </diV>
      </b-card>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import decryptData from '@/store/auth/decryptData'

export default {
  data() {
    return {
      loading: false,
      invoiceData: {
        request_code: '',
        payment_method: '',
        payment_status: '',
        office: {
          name: '',
          country: '',
          tax: '',
          other_tax: '',
        },
        certificate: {
          main: '',
          sub: '',
        },
        factory: {
          name: '',
          address: '',
          phone: '',
        },
        delegator: {
          name: '',
          phone: '',
          email: '',
        },
        price: {
          certificate: '',
          tax: '',
          other_tax: '',
          total: '',
        },
        offer: {
          name: '',
          amount: '',
        },
      },
    }
  },
  mounted() {
    this.showInvoice()
  },
  methods: {
    showInvoice() {
      if (this.$route.params.id) {
        this.loading = true
        axios
          .get(`/requests/${this.$route.params.id}/invoice`)
          .then(res => {
            this.invoiceData = decryptData(res.data.payload).data.data.invoice
            // console.log(this.invoiceData)
          })
          // .catch(error => {
          //   console.log(error)
          // })
          .finally(() => {
            this.loading = false
          })
      }
    },
    printInvoice() {
      const printContents = document.getElementById('invoice-card').innerHTML
      const originalContents = document.body.innerHTML

      document.body.innerHTML = printContents
      window.print()
      document.body.innerHTML = originalContents
      window.location.reload() // Optional: Reload the page to restore original content
    },
  },
}
</script>

  <style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #1ea0fc;
  color: white;
}</style>
